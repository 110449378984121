import { storeToRefs } from 'pinia'
import { v4 as uuidv4 } from 'uuid'
import { useParkingStore } from '~/stores/parking'

export default defineNuxtRouteMiddleware(async (to) => {
// Get Webview global state
    const isWebview = useState('state_isWebview')

    // Fetch parking store
    const { getNotifyResponse } = useParkingStore()

    const localePath = useLocalePath()

    // Define some basics
    const queryParams = to.query
    const params = to.params
    const centerUid = params.centerUid
    const meta = to.meta

    // Redirect parking pages to center overview page if parking is not available at all
    // Also redirect payment page if center is available in list but does not have ticket payment
    if (meta?.parking && !isWebview.value) {
        const { data: parkingCenters, suspense: parkingCenterSuspense } = useFetchParkingCenterList()

        await parkingCenterSuspense()

        const center = parkingCenters.value?.centers.find(c => c.id === centerUid)
        const hasNoParkingFeatureAtAll = !center?.productTypes
        const hasNoParkingTicketFeature = !center?.productTypes?.includes('ParkingTicket')
        const targetPath = localePath({ name: 'centerUid', params: { centerUid } })
        const isPayTicketPage = meta?.parkingOverviewPage

        if (!center || (isPayTicketPage && (hasNoParkingFeatureAtAll || hasNoParkingTicketFeature))) {
            return navigateTo(targetPath)
        }
    }

    // Handle payment redirect
    if (meta?.paymentRedirect && queryParams.redirectUrl) {
        const isFailure = queryParams.redirectUrl === 'failure'
        const transactionId = queryParams.transactionId
        const correlationId = queryParams.correlationId // Correlation id from `/api/payment/transactions/${transactionId}/prepare/${paymentMethod}`
        const isWeb = queryParams.isWebview === 'false'
        const webFailureUrl = `/payment/failure?scope=${queryParams.scope}`
        const appFailureUrl = 'myce://paymentfailed'
        const webSuccessUrl = `/payment/success?scope=${queryParams.scope}`
        const appSuccessUrl = 'myce://paymentsuccess'
        const finalizeEndpointCorrelationId = uuidv4()

        if (isFailure) {
            // We do not have to wait if this is success or failure we go to failure page
            await getNotifyResponse(
                {
                    finalizeEndpointCorrelationId,
                    transactionId,
                    body: {
                        status: 'failed'
                    }
                }
            )

            // Here we pass correlation id from authorize endpoint because this did not work, and we need to debug it
            return navigateTo(isWeb ? `${webFailureUrl}&correlationId=${correlationId}` : appFailureUrl, { external: true })
        } else {
            const finalizeEndpointSuccess = await getNotifyResponse({
                finalizeEndpointCorrelationId,
                transactionId,
                body: {
                    status: 'approved'
                }
            })

            // Finalize endpoint returns 202 statusCode
            if (finalizeEndpointSuccess) {
                return navigateTo(isWeb ? webSuccessUrl : appSuccessUrl, { external: true })
            } else {
                // Something went wrong, so redirect to failure
                // Here we pass correlation id from finalize endpoint because this did not work, and we need to debug it
                return navigateTo(isWeb ? `${webFailureUrl}&correlationId=${finalizeEndpointCorrelationId}` : appFailureUrl, { external: true })
            }
        }
    }
})
